.badgeCard {
	width: 200px;
	height: 100px;
	border-radius: 0.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	transition: width 1s;
}

.customersCard {
	width: 300px;
	height: 321px;
	border-radius: 0.4rem;
	text-align: center;
	cursor: pointer;
}

.infoCard {
	width: 20rem;
	height: 9rem;
	border-radius: 0.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.chart {
	height: 370px;
	margin-block: 2rem;
	border-radius: 0.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}
@media screen and (max-width: 990px) {
	.badgeCard {
		width: 100px;
		height: 110px;
		border-radius: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
	}
}

.trophy {
	height: 70px;
	width: 70px;
	object-fit: contain;
}
