.thirdPartyLogisticContainer {
	padding: 1.5rem 2rem;
	display: flex;
	justify-content: space-between;

	.preferenceCardList {
		margin: 0;
		padding: 0 1rem;
	}
}
