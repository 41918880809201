.mainParentDiv {
	background-image: url('https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/assets/tracking_page/darkBackground.webp');
	h1,
	p,
	h2,
	h3,
	h4,
	h5,
	h6,
	span,
	b {
		margin: 0;
		padding: 0;
		font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	}
	height: 100%;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 55% 40% 5%;
	.wherehouseLogo {
		position: absolute;
		top: 2rem;
		left: 4rem;
	}
	.rightSideBox {
		padding: 2rem;
		width: 100%;
		.headingTop {
			margin-top: 5rem;
			h1 {
				color: white;
				font-weight: 700;
				line-height: 4rem;
				span {
					color: #22dabb;
				}
			}
		}
		.searchBox {
			display: flex;
			justify-content: space-between;
			position: relative;
			z-index: 2;
			border: #22dabb 2px solid;
			font-size: 1.5rem;
			width: 100%;
			height: 3.5rem;
			padding: 0.3rem;
			align-items: center;
			background: #ffffff;
			box-shadow: 0px 4px 30px rgb(97, 96, 96);
			border-radius: 10px;
			margin-top: 4rem;
			button {
				width: 100%;
				max-width: 7rem;
				height: 100%;
				background: #5463ff;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border-radius: 8px;
				border: none;
				font-weight: 700;
				font-size: 1.2rem;
				line-height: 2rem;
				color: #ffffff;
			}
			input {
				width: 100%;
				height: 100%;
				border: none;
				padding: 0.7rem;
				padding-bottom: 0.8rem;
			}
			input:focus {
				outline: none;
			}
			input::placeholder {
				font-size: 1rem;
				// line-height: 2rem;
				color: #534340;
			}
		}
	}
	.greenBottom {
		position: absolute;
		bottom: 0;
		right: 20rem;
	}
	.blueBottom {
		position: absolute;
		bottom: 0;
		right: 2rem;
	}
	.bottomScooterLogo {
		display: none;
		width: 100%;
		position: absolute;
		bottom: 0;
		img {
			width: 100%;
		}
	}
}

.rightRiderLogo {
	height: 100vh;
	width: 100%;
	img {
		width: 100%;
		height: 100vh;
	}
}

@media screen and (max-width: 1200px) {
	.mainParentDiv {
		grid-template-columns: 50% 48% 2%;
	}
	.rightSideBox {
		padding: 0px;
		margin: 0;
		.headingTop {
			padding: 0;
			width: 100%;
			h1 {
				font-size: 2rem;
			}
		}
	}
	.searchBox {
		background-color: red;
	}
}

@media screen and (max-width: 1000px) {
	.mainParentDiv {
		display: flex;
		flex-direction: column;
		.headingTop {
			width: 80%;
			padding: 2rem;
			margin-left: 3rem;
		}
		.wherehouseLogo {
			left: 1.5rem;
			top: 1.5rem;
		}
		#searchBoxWithId {
			width: 80%;
			margin: auto;
		}
		.greenBottom,
		.blueBottom,
		.rightRiderLogo {
			display: none;
		}
	}
	#bottomLogoWithId {
		display: block;
	}
}

@media screen and (max-width: 700px) {
	#searchBoxWithId {
		height: 3.8rem;
		width: 120%;
		button {
			height: 100%;
			width: 7.5rem;
			font-size: 1rem;
			font-weight: 400;
		}
		input::placeholder {
			font-size: 0.9rem;
		}
	}
	.rightSideBox {
		padding: 0 !important;
		margin: 0;
		.headingTop {
			padding: 3rem 0 4rem 0;
			h1 {
				font-size: 1.5rem;
				line-height: normal;
			}
		}
	}
}
