.headerChangerColumns {
	background-color: white;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	> div {
		display: flex;
		flex-direction: column;
		row-gap: 0.5rem;
	}
}
.selectDrawer {
	width: 100%;
}

.linksDiv {
	display: flex;
	justify-content: space-between;
	padding: 0.5em;
	> :nth-child(1) {
		display: flex;
		align-items: center;
		column-gap: 0.5rem;
	}

	> :nth-child(2) {
		display: flex;
		align-items: center;
	}
}
.inputCardDiv {
	display: flex;
	flex-direction: column;
	row-gap: 2px;
	> div {
		display: flex;
		input {
			width: 100%;
			border: 1px solid #aba8a8;
			border-bottom-left-radius: 4px;
			border-top-left-radius: 4px;
			border-right: none;
			height: 30px;
			padding: 0.5rem;
		}
	}
}

.addLinkButton {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
}
.publishButton {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	padding-top: 0;
}
.overflowDiv {
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
	height: 86vh;
	display: flex;
	gap: 10px;
	flex-direction: column;
	padding: 0 1rem 2rem 1rem;
}
