.pickupAddress {
	gap: 2px;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	.tag {
		float: right;
		border-radius: 1rem;
		font-size: 0.7rem;
	}
}

.pickupDetails {
	margin: 0.5rem;
	width: 100%;
}

.searchContainer {
	display: flex;
	flex-direction: column;
	min-width: 300px;
}

.orderStatusFilter {
	display: flex;
	flex-direction: column;
	min-width: 200px;
	max-width: 200px;
}
