.deliveryPartnerCard {
	width: 500px;
	margin: 2rem 0;
	padding: 1.5rem;
	border-radius: 1rem;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	background-color: white;
	box-sizing: border-box;
	position: relative;

	.header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	.name {
		margin: 0;
		text-transform: capitalize;
		font-weight: 600;
		margin-bottom: 0.5rem;
	}

	.priority {
		margin: 0;
	}

	.body {
		margin-top: 1.5rem;
	}

	.dropdownIcon {
		position: absolute;
		bottom: 10px;
		margin: 0 auto;
		left: 0;
		right: 0;
		text-align: center;
	}

	.sellerIcon {
		height: 6.25rem;
		width: 6.25rem;
		margin-right: 1rem;
		object-fit: contain;
	}

	.flex {
		display: flex;
	}
}
