.dataCard {
	background-color: white;
	border-radius: 0.2rem;
	border: 1px solid #e7e7e7;
	position: relative;
	padding: 1rem;
	cursor: pointer;
	.roundBox {
		position: absolute;
		top: 1rem;
		right: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-image: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
		width: 3rem;
		height: 3rem;
		font-size: 1.5rem;
		color: white;
	}
	.topcardDiv {
		display: flex;
		flex-direction: column;
		> p {
			font-weight: 700;
			font-size: 0.8rem;
		}
	}
	.percentagePara {
		color: #8898aa;
		font-size: 0.7rem;
	}
}
.mostSellingChart {
	position: relative;
	border: 1px solid #e7e7e7;
	border-radius: 0.2rem;
	background-color: white;
	grid-area: mostSellingChart;
	> .chartParentDiv {
		padding: 0.5rem;
	}
}
.last30Days {
	position: absolute;
	bottom: 0.6rem;
	right: 1rem;
	font-size: 0.7rem;
	font-weight: 600;
}

.topHeading {
	text-align: center;
	border-bottom: 1px solid #e7e7e7;
	font-weight: 700;
	font-size: 0.9rem;
	padding: 0.7rem;
}
.indiaMap {
	position: relative;
	border: 1px solid #e7e7e7;
	border-radius: 0.2rem;
	background-color: white;
	grid-area: indiaMap;
}

.pieChart {
	border: 1px solid #e7e7e7;
	position: relative;
	border-radius: 0.2rem;
	background-color: white;
	box-sizing: border-box;
}
