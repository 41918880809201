.searchInput {
	margin-top: 1rem;
	padding: 0 1.5rem;
	:global {
		button.ant-btn {
			border-left: unset;
			border-top-right-radius: 5px !important;
			border-bottom-right-radius: 5px !important;
			svg {
				margin-top: -1px;
			}
		}
		input {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
	}
}

.menu {
	margin-top: 1.5rem;
	max-height: calc(100vh - 13vh);
	padding: 0;
	padding-right: 0;
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
	.menuItem {
		font-size: 1rem;
		font-weight: 500;
		margin: 0 0;
		text-transform: capitalize;
		:global {
			.ant-menu-title-content {
				margin-left: 1rem;
			}
		}
	}
	.activeMenuItem {
		font-size: 1rem;
		font-weight: 500;
		margin: 0 0;
		color: #1890ff !important;
		background-color: #e6f7ff !important;
		border-right: 3px solid #1890ff;
		svg {
			path {
				fill: #1890ff !important;
			}
		}
		:global {
			.ant-menu-title-content {
				margin-left: 1rem;
			}
		}
	}
	:global {
		.ant-menu-item-active,
		.ant-menu-item-selected {
			svg {
				path {
					fill: #1890ff !important;
				}
			}
		}
	}
}
.bottomButtons {
	margin-top: auto;
	padding: 1rem;
	:global {
		button {
			width: 100%;
			border-radius: 4px;
			font-weight: 700;
			padding: 5px 0;
			height: auto;
			font-size: clamp(0.825rem, 0.825rem, 1rem);
			&:last-child {
				margin-top: 0.7rem;
				background-color: rgba(230, 247, 255, 1);
				color: rgba(24, 144, 255, 1);
				border-color: #daedf7;
				.anticon {
					vertical-align: 0;
				}
				&:hover {
					background-color: #f0f7fb;
				}
			}
		}
	}
}

.rotatableIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	height: 4rem;
	width: 1.2rem;
	background-color: #fff;
	position: absolute;
	color: #1890ff;
	box-shadow: 2px 0px 1px rgb(242, 240, 240);
	right: -1.1rem;
	top: 0;
	border-bottom-right-radius: 4px;
	z-index: 100;
	cursor: pointer;
}

.rotateIt {
	transform: rotate(0.5turn);
}

.mainSlideBarContainer {
	height: 100%;
	margin-top: 0;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

@media screen and (max-width: 990px) {
	.mainSlideBarContainer {
		height: calc(100vh - 1rem);
	}
}
