.searchContainer {
	display: flex;
	flex-direction: column;
	padding-left: 1rem;
	min-width: 300px;
}

.downloadButton {
	margin-top: 1rem;
}

.orderStatusFilter {
	display: flex;
	flex-direction: column;
	min-width: 200px;
	max-width: 200px;
}
