.topButton {
	padding: 1rem;
	height: 94vh;
	padding-bottom: 3rem;
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
	padding-top: 1.2rem;
	> .gridAreaParentDiv {
		position: relative;
		display: grid;
		column-gap: 1rem;
		row-gap: 1rem;
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas:
			'three four one six'
			'five two indiaMap indiaMap'
			'mostSellingChart mostSellingChart indiaMap indiaMap';
		button {
			position: absolute;
			top: 1.5rem;
			right: 2rem;
			background-image: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
			border: none;
			color: white;
			padding: 0.3rem;
			width: 8rem;
			border-radius: 0.2rem;
			font-weight: 400;
			font-size: 1.1rem;
			&:hover {
				color: white;
				background-image: linear-gradient(87deg, #75daeb 0, #6ca7f4 100%);
			}
			&:active {
				box-shadow: 0px 0px 3px #11cdef;
			}
		}
	}
}

.pieChartDiv {
	padding-top: 1rem;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 1rem;
}

.one {
	grid-area: one;
}

.two {
	grid-area: two;
}
.three {
	grid-area: three;
}
.four {
	grid-area: four;
}
.five {
	grid-area: five;
}
.six {
	grid-area: six;
}

@media screen and(max-width: 900px) {
	.topButton {
		> .gridAreaParentDiv {
			grid-template-columns: repeat(2, 1fr);
			margin: auto;
			grid-template-areas:
				'three four'
				'one six'
				'five two'
				'indiaMap indiaMap'
				'mostSellingChart mostSellingChart';
		}
	}
}

@media screen and(max-width: 600px) {
	.topButton {
		width: 100vw;
		.gridAreaParentDiv {
			grid-template-columns: repeat(1, 1fr);
			grid-template-areas:
				'three'
				'four'
				'one'
				'six'
				'five'
				'two'
				'indiaMap'
				'indiaMap'
				'mostSellingChart'
				'mostSellingChart';
		}
		.pieChartDiv {
			display: flex;
			flex-direction: column;
			row-gap: 1rem;
		}
	}
}
