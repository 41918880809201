.main {
	position: fixed;
	top: 53px;
	bottom: 0;
	right: 0rem;
	width: 28rem;
	background: #ffffff;
	box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
	overflow-y: auto;
	transition: 0.5s linear;
	z-index: 2;

	.orderInfoContainer {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&.open {
		right: 0;
	}
	.paddedRow {
		padding: 1.4rem;
		padding-bottom: 0;
	}
	.row1 {
		margin-bottom: 2.5rem;
		:global {
			.title {
				font-size: 1.6rem;
			}
			.sub {
				font-size: 0.9rem;
				color: rgba(24, 144, 255, 1);
				border-bottom: 1px solid rgba(24, 144, 255, 1);
			}
		}
	}

	.row2 {
		margin: 2.5rem 0;
		:global {
			.row2-progress {
				.dot {
					height: 15px;
					width: 15px;
					background-color: rgba(149, 191, 70, 1);
					border-radius: 50%;
					display: block;
					line-height: 0;
					margin: auto;
				}
				.line {
					border-right: 4px solid rgba(149, 191, 70, 1);
					height: 3rem;
					width: 0;
					margin: auto;
					margin-top: -2px;
					line-height: 0;
				}
				.text-col {
					margin-top: -0.5rem;
					.title {
						font-size: 1.1rem;
					}
					.sub {
						color: rgba(120, 119, 119, 1);
						font-size: 0.9rem;
						line-height: 0.9rem;
						margin-bottom: 15px;
					}
				}

				&:last-child {
					.line {
						display: none;
					}
				}
			}
		}
	}

	.row3 {
		margin: 2.5rem 0;

		:global {
			.ant-col {
				padding: 0 0.7rem;
				button {
					width: 100%;
					height: auto;
					border: solid 1px;
					font-size: 0.8rem;
					padding-top: 0.4rem;
					padding-bottom: 0.4rem;
				}
			}
		}
	}

	.mapWrap {
		height: 350px;
		width: 100%;
	}

	.row4 {
		margin: 2.5rem 0;
		.icon {
			font-size: 16px;
			padding-top: 3px;
		}

		.return_row {
			justify-content: space-between;
			width: 100%;
			align-items: center;
		}
		:global {
			.title {
				font-size: 1.5rem;
			}
			.sub {
				color: rgba(120, 119, 119, 1);
				font-size: 0.9rem;
				svg {
					vertical-align: text-top;
				}
			}
			.row4-row {
				margin-top: 0.5rem;
				&:first-child {
					margin-top: 0;
				}
				.right-text {
					display: inline-block;
					width: 8rem;
				}
			}
		}
	}

	.row5 {
		position: sticky;
		bottom: 0px;
		background-color: white;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
		padding: 2.5rem 1.5rem 1.5rem 1.5rem;
		:global {
			.ant-col {
				&:first-child {
					padding-right: 0.5rem;
				}
				&:last-child {
					padding-left: 0.5rem;
				}
				button {
					width: 100%;
					border-radius: 4px;
					font-size: 1.2rem;
					font-weight: 500;
					padding: 5px 0;
					height: auto;
				}
			}
		}
	}
}
